import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Attach event listener to the global exempt checkbox
    this.globalExempt = document.querySelector("[data-withholding-target='globalExempt']");
    if (this.globalExempt) {
      this.globalExempt.removeEventListener("change", this.toggleFields); // Remove any previous listener
      this.globalExempt.addEventListener("change", this.toggleFields.bind(this)); // Attach new one
    } 

    // Attach event listeners to additional withholding input only
    document.querySelectorAll("[data-withholding-target='additionalWithholding']").forEach((field) => {
      field.removeEventListener("input", this.calculateTotal); // Remove existing listener
      field.addEventListener("input", this.calculateTotal.bind(this)); // Attach new one
    });
    this.attachListeners();
    this.toggleFields();
  }

  attachListeners() {
    const fields = [
      "#employee_w4_number_of_other_dependents",
      "#employee_w4_number_of_children",
      "#employee_address_attributes_state"
    ];

    fields.forEach((selector) => {
      const field = document.querySelector(selector);
      if (field) {
        field.removeEventListener("input", this.toggleFields);
        field.removeEventListener("change", this.toggleFields);
        field.addEventListener("input", this.toggleFields.bind(this)); // for text inputs
        field.addEventListener("change", this.toggleFields.bind(this)); // for select dropdowns
      }
    });

  }

  toggleFields() {
    let isExempt = this.globalExempt.checked;
    let defaultTotalDependents = this.data.get("totaldependent");
    let defaultTotalWithholding = this.data.get("totalwithholding");
    
    let employeeState = document.querySelector("#employee_address_attributes_state")?.value; // Get employee's state
      
    document.querySelectorAll(".form-row").forEach((row) => {
      let stateField = row.querySelector("[data-withholding-target='state']");
      let exemptField = row.querySelector("[data-withholding-target='exempt']");
      let totalDependents = row.querySelector("[data-withholding-target='totalDependents']");
      let totalWithholding = row.querySelector("[data-withholding-target='totalWithholding']");
      
      if (!stateField || !exemptField) return; // Skip if missing fields

      let stateValue = stateField.value;
      // Only uncheck 'exempt' if this row matches employee.state
      if ((stateValue.toLowerCase() === employeeState?.toLowerCase()) && isExempt === false) {
        exemptField.checked = false;
      } else {
        exemptField.checked = true;
      }

      // Set default values for total dependents and total withholding
      if (exemptField.checked) {
        if (totalDependents) totalDependents.value = defaultTotalDependents;
        if (totalWithholding) totalWithholding.value = defaultTotalWithholding;
      } 
      this.calculateTotal();
    });
  }

  calculateTotal() {
    let defaultTotalDependents = this.data.get("totaldependent");
    let defaultTotalWithholding = this.data.get("totalwithholding");

    document.querySelectorAll(".form-row").forEach((row) => {
      let exemptField = row.querySelector("[data-withholding-target='exempt']");
      if (!exemptField) return;

      let childDeductionAmount = this.data.get("childdeductionamount");
      let otherDeductionAmount = this.data.get("otherdeductionamount");

      let isExempt = exemptField.checked;
      let childrenField = row.querySelector("[data-withholding-target='dependentsChildren']");
      let otherField = row.querySelector("[data-withholding-target='dependentsOther']");
      let additionalField = row.querySelector("[data-withholding-target='additionalWithholding']");
      let totalDependentsField = row.querySelector("[data-withholding-target='totalDependents']");
      let totalWithholdingField = row.querySelector("[data-withholding-target='totalWithholding']");

      let children_diduction_val = parseInt(childDeductionAmount || "0");
      let other_diduction_val = parseInt(otherDeductionAmount || "0");
      let additional = parseInt(additionalField?.value || "0");

      let childValue = parseInt(document.querySelector("#employee_w4_number_of_children")?.value || "0");
      let otherValue = parseInt(document.querySelector("#employee_w4_number_of_other_dependents")?.value || "0");

      let totalDependents = isExempt ? defaultTotalDependents : childValue + otherValue;
      let totalWithholding = isExempt ? defaultTotalWithholding : (childValue * children_diduction_val) + (otherValue * other_diduction_val) + additional;

      if (isExempt) {
        childValue = 0
        otherValue = 0
      }
      if (childrenField) childrenField.value = childValue;
      if (otherField) otherField.value = otherValue;
      if (totalDependentsField) totalDependentsField.value = totalDependents;
      if (totalWithholdingField) totalWithholdingField.value = totalWithholding;
    });
  }
}
