import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    console.log("Stimulus Controller Initialized");
    
    this.listenEmployeeInput();
    this.listenPayrollInput();
    this.listenForceManuallyToggle();
    this.triggerOnPageLoad();
  }

  employeeForceManually() {
    return document.querySelector("#employee_force_manually")?.checked || false;
  }

  listenEmployeeInput() {
    const input = document.querySelector("#employee_tax_id"); // SSN Input
    if (!input) return;

    input.addEventListener("input", () => {
      const ssnNumber = input.value;
      if (ssnNumber !== "") {
          let replacedSSN = ssnNumber.replace(/-/g, "");
          if (replacedSSN.length > 8) {
              this.debouncedFetch(replacedSSN);
          }
        }
    });

    input.addEventListener("keyup", () => {
      clearTimeout(this.timer);
    });

    // input.addEventListener("change", () => {
    //   const ssnNumber = input.value;
    //   if (ssnNumber !== "") {
    //       let replacedSSN = ssnNumber.replace(/-/g, "");
    //       if (replacedSSN.length > 8) {
    //           this.debouncedFetch(replacedSSN);
    //       }
    //     }
    // });
  }

  listenPayrollInput() {
    const payrollInput = document.querySelector("#employee_payroll_id");
    if (!payrollInput) return;

    payrollInput.addEventListener("input", () => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (payrollInput.value.trim() === "") return;
        this.triggerOnPageLoad();
      }, 2000);
    });

    // payrollInput.addEventListener("change", () => {
    //   this.triggerOnPageLoad()
    // });
  }

  listenForceManuallyToggle() {
    const toggle = document.querySelector("#employee_force_manually");
    if (!toggle) return;

    toggle.addEventListener("change", () => {
      if (!toggle.checked) {
        console.log("Force manually unchecked. Running validation...");
        this.triggerOnPageLoad();
      }
    });
  }

  triggerOnPageLoad() {
    const input = document.querySelector("#employee_tax_id");
    if (input && input.value.trim() !== "") {
      const ssnNumber = input.value;
      if (ssnNumber !== "") {
          let replacedSSN = ssnNumber.replace(/-/g, "");
          if (replacedSSN.length > 8) {
            this.fetchPayroll(replacedSSN);
          }
        }
    }
  }

  debouncedFetch(ssnNumber) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchPayroll(ssnNumber);
    }, 1);
  }

  fetchPayroll(identifier) {
    if (this.employeeForceManually() || identifier.trim() === "") return;

    const payrollInput = document.querySelector("#employee_payroll_id");
    if (!payrollInput) return;
    
    fetch(`/fetchEmployeeNumber/${identifier}?payroll=${payrollInput.value}`)
      .then(response => response.ok ? response.json() : Promise.reject("Fetch failed"))
      .then(data => {
        if (data.payroll_id) {
          const newPayrollId = data.payroll_id;
          const oldPayrollId = payrollInput.value;

          if (oldPayrollId && oldPayrollId !== newPayrollId) {
            console.log("Mismatch detected.");
            this.showiOSStyleModal(oldPayrollId, newPayrollId);
          } else {
            console.log("Payroll ID is the same.");
            payrollInput.value = newPayrollId;
          }
        }
      })
      .catch(error => console.error("Fetch error:", error));
  }

  validatePayrollInput(value) {
    console.log("Validating payroll input:", value);
    if (value.trim() !== "") {
      this.fetchPayroll(value);
    }
  }

  showiOSStyleModal(oldPayrollId, newPayrollId) {
    const userLang = this.getLocale();
    const translations = {
      en: {
        title: "Mismatch Detected",
        message: `Employee number <strong>${oldPayrollId}</strong> differs from autogenerated <strong>${newPayrollId}</strong>.`,
        keepOld: `Keep employee number ${oldPayrollId}`,
        updateNew: `Update to ${newPayrollId}`,
      },
      es: {
        title: "Dato discordante detectado",
        message: `El número de empleado <strong>${oldPayrollId}</strong> difiere del autogenerado <strong>${newPayrollId}</strong>.`,
        keepOld: `Mantener el número de empleado ${oldPayrollId}`,
        updateNew: `Cambiarlo a ${newPayrollId}`,
      },
    };

    const { title, message, keepOld, updateNew } = translations[userLang];

    document.querySelector("#ios-style-modal")?.remove();
    document.querySelector(".modal-backdrop")?.remove();

    document.body.style.overflow = "hidden";

    const backdrop = document.createElement("div");
    backdrop.classList.add("modal-backdrop");
    Object.assign(backdrop.style, {
      position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", alignItems: "center",
      justifyContent: "center", zIndex: "1000"
    });

    const modal = document.createElement("div");
    modal.id = "ios-style-modal";
    Object.assign(modal.style, {
      backgroundColor: "#fff", padding: "20px", borderRadius: "14px",
      width: "90%", maxWidth: "350px", textAlign: "center", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: "1050", fontFamily: "Arial, sans-serif"
    });

    const titleElement = document.createElement("h3");
    titleElement.textContent = title;
    Object.assign(titleElement.style, { marginBottom: "10px", fontSize: "18px", fontWeight: "bold" });

    const messageElement = document.createElement("p");
    messageElement.innerHTML = message;
    Object.assign(messageElement.style, { fontSize: "14px", marginBottom: "15px" });

    const buttonContainer = document.createElement("div");
    Object.assign(buttonContainer.style, { display: "flex", flexDirection: "column", gap: "10px" });

    const keepButton = document.createElement("button");
    keepButton.textContent = keepOld;
    Object.assign(keepButton.style, this.buttonStyle("#007AFF"));
    keepButton.onclick = () => {
      document.querySelector("#employee_force_manually").checked = true;
      document.querySelector("#employee_payroll_id").value = oldPayrollId;
      this.closeModal();
    };

    const newPayrollButton = document.createElement("button");
    newPayrollButton.textContent = updateNew;
    Object.assign(newPayrollButton.style, this.buttonStyle("#007AFF"));
    newPayrollButton.onclick = () => {
      document.querySelector("#employee_force_manually").checked = false;
      document.querySelector("#employee_payroll_id").value = newPayrollId;
      this.closeModal();
    };

    buttonContainer.appendChild(keepButton);
    buttonContainer.appendChild(newPayrollButton);
    modal.appendChild(titleElement);
    modal.appendChild(messageElement);
    modal.appendChild(buttonContainer);
    backdrop.appendChild(modal);
    document.body.appendChild(backdrop);
  }

  buttonStyle(color) {
    return {
      backgroundColor: color, color: "#fff", padding: "12px", border: "none",
      borderRadius: "8px", fontSize: "14px", cursor: "pointer", fontWeight: "bold",
      width: "100%", textAlign: "center"
    };
  }

  getLocale() {
    const cookies = document.cookie.split("; ");
    const localeCookie = cookies.find((row) => row.startsWith("locale="));
    return localeCookie ? localeCookie.split("=")[1] : "en";
  }

  closeModal() {
    document.querySelector("#ios-style-modal")?.remove();
    document.querySelector(".modal-backdrop")?.remove();
    document.body.style.overflow = "auto";
  }
}

